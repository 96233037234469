import * as React from "react";
import { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import {
    lazyInject,
    TYPES,
    GSAdminAction,
    fmtMsg,
} from "@app/util/index";
import { PathConfig } from "@app/config/pathconfig";
import { GSAdminLocale, SchoolLocale } from "@app/locales/localeid";
import { IRegionService } from "@app/service/admin/regions/interface";
import { RegionModelPropNames } from "@app/service/admin/regions/model";
import {
    withRouter,
    GLForm,
    GLFormComponentProps,
    GLUtil,
    GLAction,
} from "gl-commonui";
import { SecondaryTitle } from "@app/components";
import { PageTitleDelBtn, PageTitleResetBtn } from "@app/components/school/school-page-title-btn";
import "./regionsedit.less";
import { FormView } from "./components/form-view";

interface RegionsEditPageProps extends RouteComponentProps<any> { }
interface RegionsEditPageStates {
    sourcedata?: {};
}

@withRouter
@GLForm.create()
export class RegionsEditPage extends Component<
RegionsEditPageProps & GLFormComponentProps,
RegionsEditPageStates
> {
    @lazyInject(TYPES.IRegionService)
    service: IRegionService;
    regionId = GLUtil.pathParse(PathConfig.RegionEdit).regionId;
    constructor(props, context) {
        super(props, context);
        this.state = {};
    }
    componentDidMount() {
        this.initRegionInfo().then(res => {});
    }

    initRegionInfo() {
        return this.service
            .getRegionById({
                id: GLUtil.pathParse(PathConfig.RegionEdit).regionId
            })
            .then(data => {
                data.billingDay = data.billingDay.toString();
                data.disableUnitPlanGeneration = !data[RegionModelPropNames.allowGenerateUnitPlan];
                data.disableClassPromotion = !data[RegionModelPropNames.allowPromoteClass];
                data.billingStartMonth = data.billingStartMonth ? data.billingStartMonth.toString() : "";
                this.setState({ sourcedata: data });
            });
    }

    deleteRegion() {
        this.service
            .delete(this.regionId)
            .then(data => {
                this.props.history.push({ pathname: PathConfig.Regions });
            })
            .catch(error => {
                //console.log(error);
            });
    }
    resetAnnual() {
        this.service.resetAnnual({ id: this.regionId })
    }
    render() {
        return (
            <div className="content-layout region-crud-edit">
                <div>
                <SecondaryTitle title={GSAdminLocale.RegionEditTitle}>
                    <div className="title-btns">
                        <GLAction action={GSAdminAction.EditRegion}>
                            <PageTitleResetBtn
                                className="reset"
                                labelId={SchoolLocale.BulkResetAnnualPreparation}
                                resetTitleId={
                                    fmtMsg({id: SchoolLocale.BulkResetAnnualPreparationTitle})
                                }
                                reset={() => { this.resetAnnual()}}
                            />
                        </GLAction>
                        <GLAction action={GSAdminAction.DeleteRegion}>
                            <PageTitleDelBtn
                                type="delete"
                                className="delete"
                                labelId={SchoolLocale.BtnDelete}
                                deleteTitleId={
                                    fmtMsg({id: GSAdminLocale.RegionEditSureDelete})
                                }
                                delete={() => this.deleteRegion()}
                            />
                        </GLAction>
                    </div>
                </SecondaryTitle>
                {this.state.sourcedata && 
                    <FormView 
                        isEdit={true}
                        regionId={this.regionId}
                        history={this.props.history}
                        sourceData={this.state.sourcedata}
                    />
                }
                </div>
            </div>
        );
    }
}
